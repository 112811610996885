*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

::selection {
	color: #fff;
	background-color: #3b82f6;
}

:root {
	font-family: Inter, sans-serif;
	font-feature-settings: 'liga' 1, 'calt' 1;
}

@supports (font-variation-settings: normal) {
	:root {
		font-family: InterVariable, sans-serif;
	}
}

html,
body {
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	#root {
		width: 100%;
	}

	.content-to-hide-on-screen {
		display: none;
	}

	@media print {
		.content-to-hide-on-screen {
			display: block;
		}
	}

	input[type='search'] {
		&::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}

		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}

	a,
	p,
	button {
		transition: all 300ms ease-in-out;

		&:hover {
			transition: all 300ms ease-in-out;
		}

		&:focus {
			transition: all 300ms ease-in-out;
		}

		&:active {
			transition: all 300ms ease-in-out;
		}
	}

	.loading-bar {
		animation: loading-wave-animation 1s ease-in-out infinite;

		&.animation-delay- {
			&100 {
				animation-delay: 100ms;
			}

			&200 {
				animation-delay: 200ms;
			}

			&300 {
				animation-delay: 300ms;
			}
		}
	}

	@keyframes loading-wave-animation {
		0% {
			height: 10px;
		}

		50% {
			height: 30px;
		}

		100% {
			height: 10px;
		}
	}

	em-emoji-picker {
		height: 30vh !important;
	}

	th {
		white-space: nowrap;
	}
}
