@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.no-spinner {
		appearance: textfield;
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}
	}
}

@layer utilities {
	.no-scrollbar {
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.bg {
	&-blue-500 {
		background-color: #3b82f6;
	}

	&-green-500 {
		background-color: #22c55e;
	}

	&-orange-500 {
		background-color: #f97316;
	}

	&-purple-500 {
		background-color: #a855f7;
	}

	&-yellow-500 {
		background-color: #eab308;
	}

	&-teal-500 {
		background-color: #14b8a6;
	}

	&-rose-500 {
		background-color: #f43f5e;
	}

	&-theme {
		&-white {
			@apply bg-white dark:bg-slate-800;
		}

		&-gray-50 {
			@apply bg-gray-50 dark:bg-slate-700;
		}

		&-gray-100 {
			@apply bg-gray-100 dark:bg-slate-600;
		}

		&-gray-200 {
			@apply bg-gray-200 dark:bg-slate-400;
		}
	}
}

.text-theme {
	&-white {
		@apply text-white dark:text-black;
	}

	&-gray-400 {
		@apply text-gray-400 dark:text-gray-700;
	}

	&-gray-600 {
		@apply text-gray-600 dark:text-gray-400;
	}

	&-gray-800 {
		@apply text-gray-800 dark:text-gray-200;
	}
}

.border-theme {
	&-white {
		@apply border-white dark:border-gray-800;
	}

	&-gray-200 {
		@apply border-gray-200 dark:border-gray-600;
	}

	&-gray-400 {
		@apply border-gray-400 dark:border-gray-700;
	}
}

.divide-theme {
	&-gray-200 {
		@apply divide-gray-200 dark:divide-gray-600;
	}
}

.ring-theme {
	&-white {
		@apply ring-white dark:ring-black;
	}

	&-black {
		@apply ring-black dark:ring-white;
	}
}

.fill-theme {
	&-gray-200 {
		@apply fill-gray-200 dark:fill-gray-600;
	}

	&-gray-600 {
		@apply fill-gray-600 dark:fill-gray-400;
	}

	&-gray-800 {
		@apply fill-gray-800 dark:fill-gray-200;
	}
}
