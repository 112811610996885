*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-moz-selection {
  color: #fff;
  background-color: #3b82f6;
}

::selection {
  color: #fff;
  background-color: #3b82f6;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}
html,
body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html #root,
body #root {
  width: 100%;
}
html .content-to-hide-on-screen,
body .content-to-hide-on-screen {
  display: none;
}
@media print {
  html .content-to-hide-on-screen,
  body .content-to-hide-on-screen {
    display: block;
  }
}
html input[type=search]::-ms-clear,
body input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
html input[type=search]::-ms-reveal,
body input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
html input[type=search]::-webkit-search-decoration, html input[type=search]::-webkit-search-cancel-button, html input[type=search]::-webkit-search-results-button, html input[type=search]::-webkit-search-results-decoration,
body input[type=search]::-webkit-search-decoration,
body input[type=search]::-webkit-search-cancel-button,
body input[type=search]::-webkit-search-results-button,
body input[type=search]::-webkit-search-results-decoration {
  display: none;
}
html a,
html p,
html button,
body a,
body p,
body button {
  transition: all 300ms ease-in-out;
}
html a:hover,
html p:hover,
html button:hover,
body a:hover,
body p:hover,
body button:hover {
  transition: all 300ms ease-in-out;
}
html a:focus,
html p:focus,
html button:focus,
body a:focus,
body p:focus,
body button:focus {
  transition: all 300ms ease-in-out;
}
html a:active,
html p:active,
html button:active,
body a:active,
body p:active,
body button:active {
  transition: all 300ms ease-in-out;
}
html .loading-bar,
body .loading-bar {
  animation: loading-wave-animation 1s ease-in-out infinite;
}
html .loading-bar.animation-delay-100,
body .loading-bar.animation-delay-100 {
  animation-delay: 100ms;
}
html .loading-bar.animation-delay-200,
body .loading-bar.animation-delay-200 {
  animation-delay: 200ms;
}
html .loading-bar.animation-delay-300,
body .loading-bar.animation-delay-300 {
  animation-delay: 300ms;
}
@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }
  50% {
    height: 30px;
  }
  100% {
    height: 10px;
  }
}
html em-emoji-picker,
body em-emoji-picker {
  height: 30vh !important;
}
html th,
body th {
  white-space: nowrap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .no-spinner {
    -webkit-appearance: textfield;
            appearance: textfield;
    -moz-appearance: textfield;
  }
  .no-spinner::-webkit-outer-spin-button, .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}
@layer utilities {
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
}
.bg-blue-500 {
  background-color: #3b82f6;
}
.bg-green-500 {
  background-color: #22c55e;
}
.bg-orange-500 {
  background-color: #f97316;
}
.bg-purple-500 {
  background-color: #a855f7;
}
.bg-yellow-500 {
  background-color: #eab308;
}
.bg-teal-500 {
  background-color: #14b8a6;
}
.bg-rose-500 {
  background-color: #f43f5e;
}
.bg-theme-white {
  @apply bg-white dark:bg-slate-800;
}
.bg-theme-gray-50 {
  @apply bg-gray-50 dark:bg-slate-700;
}
.bg-theme-gray-100 {
  @apply bg-gray-100 dark:bg-slate-600;
}
.bg-theme-gray-200 {
  @apply bg-gray-200 dark:bg-slate-400;
}

.text-theme-white {
  @apply text-white dark:text-black;
}
.text-theme-gray-400 {
  @apply text-gray-400 dark:text-gray-700;
}
.text-theme-gray-600 {
  @apply text-gray-600 dark:text-gray-400;
}
.text-theme-gray-800 {
  @apply text-gray-800 dark:text-gray-200;
}

.border-theme-white {
  @apply border-white dark:border-gray-800;
}
.border-theme-gray-200 {
  @apply border-gray-200 dark:border-gray-600;
}
.border-theme-gray-400 {
  @apply border-gray-400 dark:border-gray-700;
}

.divide-theme-gray-200 {
  @apply divide-gray-200 dark:divide-gray-600;
}

.ring-theme-white {
  @apply ring-white dark:ring-black;
}
.ring-theme-black {
  @apply ring-black dark:ring-white;
}

.fill-theme-gray-200 {
  @apply fill-gray-200 dark:fill-gray-600;
}
.fill-theme-gray-600 {
  @apply fill-gray-600 dark:fill-gray-400;
}
.fill-theme-gray-800 {
  @apply fill-gray-800 dark:fill-gray-200;
}/*# sourceMappingURL=style.css.map */